import React, { useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const BackgroundBlur = styled.div`
  display: none;
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: block;
    position: absolute;
    top: 53px;
    right: 30px;
    width: 290px;
    height: 290px;
    box-shadow: rgba(0, 0, 0, 0.29) 0px 3px 6px;
    background: rgba(0, 0, 0, 0.19);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 290px;

    &.hover {
      backdrop-filter: none;
      background: white;
    }
  }
`;

const Button = styled.a`
  display: none;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: block;
    position: absolute;
    top: 53px;
    right: 30px;
    width: 290px;
    height: 290px;
    background: url("/assets/gestaltungselemente/download-button-nobg.svg");
    background-size: 290px 290px;

    &:hover {
      background: url("/assets/gestaltungselemente/download-button-white-landing.svg");
      background-size: 290px 290px;
    }
  }
`;

const LandingButton = ({ downloadUrl }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <BackgroundBlur className={isHover ? "hover" : ""} />
      <Button href={downloadUrl} target="_blank" />
    </div>
  );
};

export default LandingButton;
