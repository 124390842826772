import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import DefaultLayout from "../layouts/default";
import Landing from "../components/Landing";
import Text from "../components/Text";
import TextWrapper from "../components/Layouting/TextWrapper";
import Title from "../components/Title";
import MitarbeiterGrid from "../components/Layouting/MitarbeiterGrid";
//import Kochen from "../components/Kochen";

interface UeberUnsProps {
  cms: {
    ueberUns: {
      title: string;
      beschreibung: string;
      bild: [
        {
          url: string;
        }
      ];
      titleMitOrangeBold: string;
      text: string;
      mitarbeiterMatrix: [
        {
          mitarbeiterName: string;
          bild: [
            {
              url: string;
            }
          ];
          beruf: string;
          motivation: string;
        }
      ];
    };
  };
}

const UeberUns: React.FC<UeberUnsProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        ueberUns: entry(type: "uberUns") {
          title
          ... on CMS_main_uberUns_Entry {
            beschreibung
            bild {
              url
            }
            titelMitOrangeBold
            text
            titelFarbe
            mitarbeiterMatrix {
              ... on CMS_mitarbeiterMatrix_mitarbeiter_BlockType {
                mitarbeiterName
                bild {
                  url
                }
                beruf
                motivation
              }
            }
          }
        }
      }
    }
  `);

  return (
    <DefaultLayout
      beschreibung={data.cms.ueberUns.beschreibung}
      titel={data.cms.ueberUns.title}
    >
      <Landing
        text={data.cms.ueberUns.titelMitOrangeBold}
        image={data.cms.ueberUns.bild[0].url}
        color={data.cms.ueberUns.titelFarbe}
        noDownload
      />
      <Title text={data.cms.ueberUns.titelMitOrangeBold} mobile />
      <TextWrapper>
        <Text text={data.cms.ueberUns.text} center />
      </TextWrapper>
      {/* <Kochen /> */}
      <MitarbeiterGrid mitarbeiter={data.cms.ueberUns.mitarbeiterMatrix} />
    </DefaultLayout>
  );
};

export default UeberUns;
