import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { breakpoints, colors, imgix } from "../styles/variables";
import { Background } from "react-imgix";

const MitarbeiterElem = styled.article`
  text-align: center;
  padding: 0 20px 27px;
  max-width: 700px;
  margin: 0 auto;

  .roundImage {
    border-radius: 600px;
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
    max-width: 316px;
    max-height: 316px;
    margin: 0 auto 13px;
    overflow: hidden;

    div {
      width: calc(100vw - 40px);
      height: calc(100vw - 40px);
      max-width: 316px;
      max-height: 316px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  h3 {
    font: 20px / 24px "RC Bold";
    letter-spacing: 1px;
    margin-bottom: 4px;
  }

  h5 {
    font: 16px / 20px "RC Regular";
    letter-spacing: 0.8px;
    margin-bottom: 27px;
  }

  h6 {
    font: 14px / 17px "RC Bold";
    color: ${colors.orange};
    letter-spacing: 0.6px;
    margin-bottom: 6px;
  }

  p {
    font: 14px / 17px "RC Regular";
    letter-spacing: 0.6px;
    margin-bottom: 20px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 44px 20px 37px;
    border-bottom: 1px solid #000;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      padding: 0 20px 37px;
    }

    &:last-of-type,
    &:nth-last-of-type(2) {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &:nth-of-type(odd) {
      border-right: 1px solid #000;
    }

    &:last-of-type:nth-of-type(odd) {
      border-right: 0;
    }

    &:nth-last-of-type(2):nth-of-type(even) {
      border-bottom: 1px solid #000;
      padding-bottom: 37px;
    }

    .roundImage {
      margin-bottom: 13px;
    }

    h3 {
      font: 28px / 33px "RC Bold";
      letter-spacing: 1.4px;
      margin-bottom: 6px;
    }

    h5 {
      font: 20px / 24px "RC Regular";
      letter-spacing: 1px;
      margin-bottom: 37px;
    }

    h6 {
      font: 18px / 21px "RC Bold";
      letter-spacing: 0.9px;
      margin-bottom: 8px;
    }

    p {
      font: 18px / 21px "RC Regular";
      letter-spacing: 0.9px;
      margin-bottom: 26px;
    }
  }
`;

interface MitarbeiterProps {
  data: {
    bild: [{ url: string }];
    mitarbeiterName: string;
    beruf: string;
    motivation?: string;
  };
}

const Mitarbeiter: React.FC<MitarbeiterProps> = ({ data }) => {
  const [motivation, setMotivation] = useState("");
  useEffect(() => {
    if (data.motivation != "") {
      setMotivation(data.motivation);
    } else {
      setMotivation("");
    }
  }, []);

  return (
    <MitarbeiterElem>
      <div className="roundImage">
        {data.bild.length > 0 ? (
          <Background src={data.bild[0].url} imgixParams={imgix.mitarbeiter} />
        ) : (
          ""
        )}
      </div>
      <h3>{data.mitarbeiterName}</h3>
      <h5>{data.beruf}</h5>
      <p dangerouslySetInnerHTML={{ __html: motivation }} />
    </MitarbeiterElem>
  );
};

export default Mitarbeiter;
