import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../styles/variables";
import LandingButton from "./LandingButton";
import Willkommen from "./Willkommen";
import { Background } from "react-imgix";
import ImgixImg from "./ImgixImg";

const LandingDiv = styled.div`
  width: 100vw;
  height: 71.733333vw;
  margin-bottom: 25px;

  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > p {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    width: 1025px;
    height: 680px;
    position: relative;
    margin-bottom: 40px;

    > p {
      display: block;
    }
  }
`;

interface LandingProps {
  image: string;
  text: string;
  noDownload?: string;
  color?: string;
  downloadUrl?: string;
}

const Landing: React.FC<LandingProps> = ({
  image,
  text,
  downloadUrl,
  color,
}) => (
  <>
    {image ? (
      <LandingDiv>
        <ImgixImg src={image} imgixParams={imgix.header} />
        {downloadUrl ? <LandingButton downloadUrl={downloadUrl} /> : ""}
        <Willkommen color={color} text={text} />
      </LandingDiv>
    ) : (
      ""
    )}
  </>
);

export default Landing;
