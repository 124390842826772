import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Mitarbeiter from "../Mitarbeiter";

const GridDiv = styled.section`
  margin-bottom: 55px;
  margin-top: 30px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
    margin-bottom: 120px;
  }
`;

interface MitarbeiterGridProps {
  mitarbeiter: [
    {
      mitarbeiterName: string;
      bild: [
        {
          url: string;
        }
      ];
      beruf: string;
      werdegang: string;
      motivation: string;
    }
  ];
}

const MitarbeiterGrid: React.FC<MitarbeiterGridProps> = ({ mitarbeiter }) => (
  <GridDiv>
    {mitarbeiter.map((entry) => (
      <Mitarbeiter data={entry} />
    ))}
  </GridDiv>
);

export default MitarbeiterGrid;
