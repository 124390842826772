import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

interface WillkommenProps {
  mobile?: boolean;
  text: string;
  color?: string;
}

const WillkommenText = styled.p`
  display: none;
  font: 28px / 40px "RC Light";
  letter-spacing: 3.5px;
  margin-bottom: 18px;
  width: 100%;
  text-align: center;
  /* color: ${(props) => props.color}; */

  strong,
  b {
    font-family: "RC Bold";
    color: ${colors.orange};
  }

  &.mobile {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: block;
    position: absolute;
    bottom: 54px;
    color: ${(props) => props.color};
    font: 64px / 75px "RC Light";
    letter-spacing: 8px;

    &.mobile {
      display: none;
    }
  }
`;

const Willkommen: React.FC<WillkommenProps> = ({ mobile, text, color }) => (
  <WillkommenText
    className={mobile ? "mobile" : ""}
    color={color ? color : "#fff"}
    dangerouslySetInnerHTML={{
      __html: text ? text : "Her<b>z</b>lich Willkommen",
    }}
  />
);

export default Willkommen;
